#root {
  height: 100vh;
  width: 100vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: #790c68;
}

